import { useEffect, useState, FormEvent } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { IoIosCopy } from 'react-icons/io';
import { FcExpired } from 'react-icons/fc';
import { FaArrowLeft, FaCartArrowDown } from 'react-icons/fa';
import { PayPalButton } from 'react-paypal-button-v2';

import api, { apiCoinbase } from 'services/api';

import {
  PaymentMethodsContainer,
  CountdownContainer,
  TimeForOrderText,
  BolderText,
  CurrencyContainer,
  CurrencyItemsContainer,
  Header,
  PaymentTitle,
  CurrencyPrice,
  QuoteText,
  Form,
  StyledTextInput,
  OrderContainer,
  OrderTitleText,
  QrCodeContainer,
  AddressTextInput,
  OrderDetailsContainer,
  DetailItem,
  ItemText,
  PaypalOrderContainer,
  NormalText,
  PaypalOrderTextContainer,
  OrderExpiredContainer,
  OrderExpiredText,
  StyledBootstrapButton,
} from './styles';
import { Countdown } from 'components/Countdown';
import { copyTextToClipboard, displayPriceWithCurrency, formatDecimal } from 'shared/string-utils';
import { RadioButton } from 'components/ui/RadioButton';
import { CoinLogo } from 'components/CoinLogo';
import { BootstrapButton } from 'components/ui/BootstrapButton';
import { useSelector } from 'react-redux';
import { getUserProfile } from '~/store/modules/user/selectors';
import history from 'services/history';
import { Onchain } from './Onchain';

interface IPaymentMethods {
  currencyData: {
    currencyKey: string;
    decimalsForQuote: number;
  };
}

interface IEndpointsToUse {
  quoteEndpoint: string | undefined;
  orderEndpoint: string | undefined;
}

interface IExchangeRates {
  CAD: number;
  EUR: number;
  BRL: number;
  USD: number;
  INR: number;
}

const CURRENCY_QUOTES = ['USD', 'EUR', 'CAD', 'BRL', 'INR'];
export const SUPPORTED_PAYMENT_METHODS = {
  btc: {
    currencyKey: 'BTC',
    decimalsForQuote: 2,
    title: 'Bitcoin (maintenance)',
    isDisabled: true,
  },
  ltc: {
    currencyKey: 'LTC',
    decimalsForQuote: 2,
    title: 'Litecoin (maintenance)',
    isDisabled: true,
  },
  onchain: {
    currencyKey: 'Onchain',
    decimalsForQuote: 0,
    title: 'Onchain',
    isDisabled: false,
  },
  bdx: {
    currencyKey: 'BDX',
    decimalsForQuote: 8,
    title: 'Beldex',
    isDisabled: false,
  },
  prcy: {
    currencyKey: 'PRCY',
    decimalsForQuote: 8,
    title: 'PRivaCY',
    isDisabled: false,
  },
  paypal: {
    currencyKey: 'PAYPAL',
    decimalsForQuote: 0,
    title: 'PayPal',
    isDisabled: false,
  },
};
const FIATS = {
  usd: {
    displayName: 'USD',
    symbol: '$',
  },
  cad: {
    displayName: 'CAD',
    symbol: '$',
  },
  eur: {
    displayName: 'EUR',
    symbol: '€',
  },
  brl: {
    displayName: 'BRL',
    symbol: 'R$',
  },
  inr: {
    displayName: 'INR',
    symbol: '₹',
  },
};

export function PaymentMethods({ currencyData }: IPaymentMethods) {
  const { decimalsForQuote } = currencyData;
  const { currencyKey } = currencyData;
  const [currency, setCurrency] = useState(FIATS.usd.displayName);
  const [currencyText, setCurrencyText] = useState('');
  const [exchangeRates, setExchangeRates] = useState<IExchangeRates | undefined>();
  const [isOrderCreated, setIsOrderCreated] = useState(false);
  const [cryptoApiRequest, setCryptoApiRequest] = useState<any>({
    sending: false,
    received: false,
    response: null,
  });
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  const [currencyValue, setCurrencyValue] = useState<string>();
  const [currencyBasedAmount, setCurrencyBasedAmount] = useState(1);
  const [amount, setAmount] = useState<number | string>(2);
  const [loadingCurrencyPrice, setLoadingCurrencyPrice] = useState(true);
  const [orderTimeToExpire, setOrderTimeToExpire] = useState<number | null>(null);
  const [endpointsToUse, setEndpointsToUse] = useState<IEndpointsToUse>({
    quoteEndpoint: undefined,
    orderEndpoint: undefined,
  });
  const isOnchainMethod = () => currencyKey === SUPPORTED_PAYMENT_METHODS.onchain.currencyKey;
  const isPrcyMethod = () => currencyKey === SUPPORTED_PAYMENT_METHODS.prcy.currencyKey;
  const isBdxMethod = () => currencyKey === SUPPORTED_PAYMENT_METHODS.bdx.currencyKey;
  const isPaypalMethod = () => currencyKey === SUPPORTED_PAYMENT_METHODS.paypal.currencyKey;
  const apiToUse = apiCoinbase;
  const { id } = useSelector(getUserProfile);
  const [isLoadingPaypalRequest, setIsLoadingPaypalRequest] = useState(false);
  const [isOrderExpired, setIsOrderExpired] = useState(false);

  const setEndpointsForCrypto = () => {
    const quoteEndpoint = 'exchange-rates';
    const orderEndpoint = isPrcyMethod() ? 'addfunds_prcy' : isBdxMethod() ? 'addfunds/BDX' : 'addfunds';

    setEndpointsToUse({
      quoteEndpoint,
      orderEndpoint,
    });
  };

  const setValuesBasedOnCurrency = () => {
    switch (currencyKey) {
      case SUPPORTED_PAYMENT_METHODS.btc.currencyKey: {
        setCurrencyText('Bitcoin');
        setEndpointsForCrypto();
        break;
      }
      case SUPPORTED_PAYMENT_METHODS.ltc.currencyKey: {
        setCurrencyText('Litecoin');
        setEndpointsForCrypto();
        break;
      }
      case SUPPORTED_PAYMENT_METHODS.bdx.currencyKey: {
        setCurrencyText('Beldex');
        setEndpointsForCrypto();
        break;
      }
      case SUPPORTED_PAYMENT_METHODS.prcy.currencyKey: {
        setCurrencyText('PRivaCY');
        setCurrencyBasedAmount(1);
        setEndpointsForCrypto();
        break;
      }
      case SUPPORTED_PAYMENT_METHODS.paypal.currencyKey: {
        setCurrencyText('PayPal');
        break;
      }
    }
  };

  const getCurrencySymbol = () => {
    switch (currency) {
      case FIATS.usd.displayName: {
        return FIATS.usd.symbol;
      }
      case FIATS.cad.displayName: {
        return FIATS.cad.symbol;
      }
      case FIATS.eur.displayName: {
        return FIATS.eur.symbol;
      }
      case FIATS.brl.displayName: {
        return FIATS.brl.symbol;
      }
      case FIATS.inr.displayName: {
        return FIATS.inr.symbol;
      }
      default: {
        return;
      }
    }
  };

  const getQuotes = async () => {
    const { quoteEndpoint } = endpointsToUse;

    const params = {
      currency: currencyKey,
    };

    if (!quoteEndpoint) return;

    try {
      const { data: response } = await apiToUse.get(quoteEndpoint, {
        params,
      });
      const { USD, EUR, BRL, CAD, INR } = response.data.rates;

      if (USD) {
        setExchangeRates({
          USD,
          EUR,
          BRL,
          CAD,
          INR,
        });
        return;
      }

      if (currencyText === 'Beldex' && !USD) {
        const { data: apiData } = await api.get(`coin_price`, {
          params: {
            coin: currencyText,
          },
        });

        if (apiData && apiData.beldex) {
          const { usd, eur, brl, cad, inr } = apiData.beldex;
          setExchangeRates({
            USD: usd,
            EUR: eur,
            BRL: brl,
            CAD: cad,
            INR: inr,
          });
        }
      }
    } catch (e) {
    } finally {
      setLoadingCurrencyPrice(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { orderEndpoint } = endpointsToUse;

    if (isPaypalMethod()) {
      setIsOrderCreated(true);
      return;
    }

    if (!orderEndpoint) return;

    const dataToServer = {
      amount: amount,
      crypto: currencyKey,
    };

    setCryptoApiRequest({
      sending: true,
      received: false,
      response: null,
    });

    try {
      const response = await api.post(orderEndpoint, dataToServer);
      setCryptoApiRequest({
        sending: false,
        received: true,
        response: response.data,
      });
      const { expires_at } = response.data;

      if (expires_at) {
        const timeDifference = differenceInMilliseconds(new Date(expires_at), new Date());
        setOrderTimeToExpire(timeDifference);
      }
      setIsOrderCreated(true);
    } catch (err: any) {
      const error: any = { err };
      const errorMessage = error.err.response.data.error;
      setCryptoApiRequest({
        sending: false,
        received: false,
        response: null,
      });
      toast.error(errorMessage);
    }
  };

  const handleClickCurrency = (currencyValue: string) => () => {
    setCurrency(currencyValue);
  };

  const handleCopyClick = () => {
    const valueToCopy = cryptoApiRequest.response.address;
    copyTextToClipboard(valueToCopy);
  };

  const handleBack = () => {
    setCryptoApiRequest({
      sending: false,
      received: false,
      response: null,
    });
    setOrderTimeToExpire(null);
    setIsOrderCreated(false);
    setIsOrderExpired(false);
  };

  const handleInputTextChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setAmount(value);
  };

  const redirect = () => {
    history.push('/dashboard');
  };

  const payPalCreateOrder = async () => {
    try {
      const params = {
        amount,
        u: id,
      };
      const { data } = await api.post(
        'paypal_payment',
        {},
        {
          params,
        }
      );

      return data.id;
    } catch (err) {
      toast.error('Something went wrong when creating the order.');
    }
  };

  const payPalOnApproveOrder = async (data: any) => {
    const params = {
      u: id,
      orderId: data.orderID,
    };
    try {
      setIsLoadingPaypalRequest(true);
      await api.put('capture_paypal_payment', {}, { params });
      toast.success('Payment successfully received! Thank you very much!', {
        autoClose: 5000,
      });
      redirect();
    } catch (err) {
      toast.error('Something went wrong when capturing the order.');
      setIsLoadingPaypalRequest(false);
    }
  };

  const renderCryptoOrder = () => {
    if (!isOrderExpired) {
      return (
        <OrderContainer>
          <OrderTitleText>
            {`To complete your order, please send `}
            <BolderText>{currencyText}</BolderText>
            {` to the `}
            <BolderText>{`exact ${currencyText} address below`}</BolderText>.
          </OrderTitleText>

          <QrCodeContainer>
            <QRCode size={150} value={cryptoApiRequest.response.address} />
            <AddressTextInput value={cryptoApiRequest.response.address} disabled />
            <BootstrapButton variant="info" onClick={handleCopyClick} shouldShowTooltip={true} tooltipText="Copied!">
              COPY ADDRESS
              <IoIosCopy />
            </BootstrapButton>
          </QrCodeContainer>

          {orderTimeToExpire && (
            <CountdownContainer>
              <TimeForOrderText>{`Time left for this order:`}</TimeForOrderText>
              <Countdown timestamp={orderTimeToExpire} onTimeIsOver={handleOrderTimeExpired} />
            </CountdownContainer>
          )}

          <OrderDetailsContainer>
            <DetailItem>
              <ItemText>{`Sending:`}</ItemText>
              <ItemText isBold>{`${cryptoApiRequest.response.criptoAmount} ${currencyKey}`}</ItemText>
            </DetailItem>
            <DetailItem>
              <ItemText>{`You will receive about:`}</ItemText>
              <ItemText isBold>{`${displayPriceWithCurrency(cryptoApiRequest.response.amount)}`}</ItemText>
            </DetailItem>
            <DetailItem>
              <ItemText>
                You can send a different <BolderText>{currencyKey}</BolderText> amount to the address above. The exact
                amount you will receive, it will be calculated at the moment <BolderText>{currencyText}</BolderText>{' '}
                network processes your transaction.
              </ItemText>
            </DetailItem>
          </OrderDetailsContainer>

          <BootstrapButton variant="primary" onClick={handleBack}>
            <FaArrowLeft />
            BACK
          </BootstrapButton>
        </OrderContainer>
      );
    }
    return (
      <OrderExpiredContainer>
        <OrderExpiredText>
          <FcExpired size={40} />
          YOUR ORDER HAS EXPIRED!
        </OrderExpiredText>
        <BootstrapButton variant="primary" onClick={handleBack}>
          <FaArrowLeft />
          BACK
        </BootstrapButton>
      </OrderExpiredContainer>
    );
  };

  const renderPaypalOrder = () => {
    if (isLoadingPaypalRequest) {
      return (
        <PaypalOrderContainer>
          <BolderText>Completing your order...</BolderText>
          <Spinner animation="border" />
        </PaypalOrderContainer>
      );
    }

    return (
      <PaypalOrderContainer>
        <PaypalOrderTextContainer>
          <NormalText>
            {'To complete your order of '}
            <BolderText>{`${amount} ${FIATS.usd.displayName}.`}</BolderText>
          </NormalText>
          <NormalText>{`Please use the PayPal checkout bellow.`}</NormalText>

          <PayPalButton createOrder={payPalCreateOrder} onApprove={payPalOnApproveOrder} />
        </PaypalOrderTextContainer>

        <BootstrapButton variant="primary" onClick={handleBack}>
          <FaArrowLeft />
          BACK
        </BootstrapButton>
      </PaypalOrderContainer>
    );
  };

  const handleOrderTimeExpired = () => {
    setIsOrderExpired(true);
  };

  useEffect(() => {
    setValuesBasedOnCurrency();
  }, []);

  useEffect(() => {
    if (exchangeRates) {
      const symbol = getCurrencySymbol();
      // @ts-ignore
      const currencyQuote = exchangeRates[currency] * currencyBasedAmount;
      setCurrencySymbol(symbol);
      const currencyValueWithDecimals = formatDecimal(currencyQuote, decimalsForQuote);
      setCurrencyValue(currencyValueWithDecimals);
    }
  }, [currency, exchangeRates]);

  useEffect(() => {
    if (endpointsToUse.quoteEndpoint) {
      getQuotes();
    }
  }, [endpointsToUse]);

  return (
    <PaymentMethodsContainer>
      {isOnchainMethod() ? (
        <Onchain />
      ) : (
        <>
          <Header>
            <PaymentTitle>{currencyText} Payment Method</PaymentTitle>
            <CoinLogo coin={currencyKey.toLocaleLowerCase()} shouldUseBorderRadius={!isPaypalMethod()} />
          </Header>
          {!isOrderCreated ? (
            <>
              {!isPaypalMethod() && (
                <CurrencyContainer>
                  <CurrencyItemsContainer>
                    {CURRENCY_QUOTES.map((currencyItem) => (
                      <RadioButton
                        key={currencyItem}
                        label={currencyItem}
                        onClick={handleClickCurrency(currencyItem)}
                        checked={currency === currencyItem}
                      />
                    ))}
                  </CurrencyItemsContainer>
                  <CurrencyPrice>
                    {`${currencyBasedAmount} ${currencyKey} = `}
                    {loadingCurrencyPrice ? (
                      <Spinner size="sm" animation="border" />
                    ) : (
                      `${currencySymbol}${currencyValue}`
                    )}
                  </CurrencyPrice>
                  <QuoteText>{`USD price will be used in ${currencyText} quote.`}</QuoteText>
                </CurrencyContainer>
              )}

              <Form onSubmit={handleSubmit} isPaypalMethod={isPaypalMethod()}>
                <BolderText>Minimum 2 USD</BolderText>
                <StyledTextInput
                  type="number"
                  placeholder="USD Amount"
                  min="2"
                  required
                  value={amount}
                  onChange={handleInputTextChange}
                />
                <StyledBootstrapButton variant="success" type="submit" disabled={cryptoApiRequest.sending}>
                  <FaCartArrowDown />
                  PAY
                </StyledBootstrapButton>
              </Form>
            </>
          ) : isPaypalMethod() ? (
            renderPaypalOrder()
          ) : (
            renderCryptoOrder()
          )}
        </>
      )}
    </PaymentMethodsContainer>
  );
}
